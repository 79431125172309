<template>
  <div class="ai-studio-container" @click="clearSelect">
    <div class="menu-bar row">
      <b-button variant="primary" data-toggle="tooltip" title="저장하기" @click="onSave"><i class="las la-save"></i></b-button>
      <b-button variant="primary" data-toggle="tooltip" title="되돌리기" :disabled="undoDataList.length === 0" @click="onUndo"><i class="las la-undo"></i></b-button>
      <b-button variant="primary" data-toggle="tooltip" title="다시실행" :disabled="redoDataList.length === 0" @click="onRedo"><i class="las la-redo"></i></b-button>
      <b-button variant="primary" data-toggle="tooltip" title="컴포넌트 복사하기" :disable="selectedComponents.length === 0" @click="copySelectedComponents"><i class="las la-copy"></i></b-button>
      <b-button variant="primary" data-toggle="tooltip" title="컴포넌트 붙여넣기" :disable="copyComponents.length === 0" @click="pasteCopiedComponents"><i class="las la-paste"></i></b-button>
      <b-button variant="danger" data-toggle="tooltip" title="컴포넌트 삭제하기" :disable="selectedComponents.length === 0" @click="deleteComponent"><i class="las la-trash"></i></b-button>
    </div>
    <div class="studio-contents-wrap row">
      <div class="ai-studio-left-wrap">
        <div class="page-menu-bar">
          <b-button class="mb-2 mr-1" data-toggle="tooltip" title="컨텐츠 추가" variant="primary" @click="addContents"><i class="ion-document-text"></i></b-button><!--컨텐츠 추가-->
          <b-button class="mb-2 mr-1" data-toggle="tooltip" title="컨텐츠 삭제" variant="danger" @click="deleteContents"><i class="ion-trash-b"></i></b-button><!--컨텐츠 삭제-->
        </div>
        <div class="page-list">
          <div v-for="(item, index) in $options.filters.contentsList(contentsList)" :key="index" @mousedown="selectContents(item, index)">
            <div class="studio-contents" :class="{active : selectedContentsList.filter(itm=>itm === item).length > 0}">
              <h6 class="text-light float-left">{{index+1}}</h6>
              <div class="float-right studio-contents-thumb bg-light" :style="getContentsStyle(item)">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ai-studio-side-wrap">
        <div class="component-menu-bar">
          <b-button
            v-for="(btn, idx) in componentButtons"
            :key="'btn_'+idx"
            :variant="btn.variant"
            v-b-modal="'modal-'+btn.id"
            :data-toggle="btn.dataToggle"
            :title="btn.title"
          >
            <i :class="btn.icon"></i>
          </b-button>
        </div>
      </div>
      <div class="ai-studio-center-wrap">
        <div class="studio-page">
          <div class="ai-studio-project-name"><p>{{projectName}}</p></div>
          <vue-resizable
          v-for="(item,index) in $options.filters.selectedIdDataList(components, 'contentsId', selectedContentsList[0])"
          :key="index"
          class="resizable"
          ref="resizableComponent"
          :class="[{active : selectedComponents.filter(itm=>itm === item).length > 0}, {['component_'+item.componentId] : components.filter(itm=>itm === item).length > 0}]"
          :dragSelector="'.component'"
          :min-width="100"
          :min-height="100"
          :width="item.width"
          :height="item.height"
          :left="item.left"
          :top="item.top"
          @resize:move="eHandler"
          @resize:start="eHandler"
          @resize:end="eHandler"
          @drag:move="eHandler"
          @drag:start="eHandler"
          @drag:end="eHandler">
          <VComponent
            :item="item"
            :characterList="characterList"
            :fileGroupList="fileGroupList"
            :fileList="fileList"
            @click="selectComponent"
            @getSrc="getSrc"
          />
        </vue-resizable>
        </div>

      </div>
      <div class="ai-studio-right-wrap">
        <VOption
          :selectedComponents="selectedComponents"
          :animationList="animationList"
          :selectedContentsList="selectedContentsList"
          :selectedAnimationList="selectedAnimationList"
          @animation:select="selectAnimation"
          @animation:show="showAnimationModal"
          @animation:delete="deleteAnimation"
        />
      </div>

      <VModal
        v-for="(value, index) in componentButtons"
        :key="'modal_'+index"
        :btn="value"
        :modalData="modalData"
        :characterList="characterList"
        :componentModal="componentModal"
        :modalFiles="modalFiles"
        @modal:hide="handleModalHide"
        @modal:show="handleModalShow"
      />

      <VAnimationModal
        :componentModal="componentModal"
        :animationData="animationData"
        :selectedAnimationTarget="selectedAnimationTarget"
        :animationTimings="animationTimings"
        :selectedContentsList="selectedContentsList"
        :animationList="animationList"
        :modelEmotes="modelEmotes"
        :components="components"
        @modal:hide="handleModalHide"
        @modal:show="handleModalShow"
      />
    </div>
  </div>
</template>
<style lang="scss">
@import url("../../assets/css/ai-studio.css");
</style>
<script>
import VueResizable from 'vue-resizable'
import { core } from '../../config/pluginInit'
import { utils } from '../../Utils/utils'
import _ from 'lodash'
import html2canvas from 'html2canvas'
import $ from 'jquery'
import VComponent from '../components/VComponent'
import VModal from '../components/VModal'
import VAnimationModal from '../components/VAnimationModal'
import VOption from '../components/VOption'
import _axios from '../services/studio'

export default {
  name: 'AIStudio',
  components: {
    VueResizable,
    VComponent,
    VModal,
    VAnimationModal,
    VOption
  },
  data () {
    return {
      replaceUrl: 'http://127.0.0.1:8000',
      characterList: [
        {
          src: require('@/assets/images/teacher.png'),
          name: 'Leona',
          titel: 'teacher',
          value: 0
        },
        {
          src: require('@/assets/images/a.png'),
          name: 'Homes',
          titel: 'Ceo',
          value: 1
        }
      ],
      componentButtons: [
        { id: 'people', variant: 'primary', dataToggle: 'tooltip', title: '모델 설정', icon: 'ion-android-people' },
        { id: 'text', variant: 'primary', dataToggle: 'tooltip', title: '텍스트 입력', icon: 'ion-android-textsms' },
        { id: 'video', variant: 'primary', dataToggle: 'tooltip', title: '동영상', icon: 'ion-ios-videocam' },
        { id: 'image', variant: 'primary', dataToggle: 'tooltip', title: '이미지', icon: 'ion-image' },
        { id: 'quiz', variant: 'primary', dataToggle: 'tooltip', title: '퀴즈', icon: 'ion-ios-lightbulb' },
        { id: 'hiddenCatch', variant: 'primary', dataToggle: 'tooltip', title: '다른그림 찾기', icon: 'las la-school' }
      ],
      projectId: 1,
      projectName: '',
      contentsList: [],
      modalFiles: [],
      selectedContentsList: [],
      componentModal: {
        people: false,
        text: false,
        video: false,
        audio: false,
        image: false,
        quiz: false,
        animation: false
      },
      modalData: {
        file: null,
        file2: null,
        img: null,
        img2: null,
        text: '',
        quiz: {
          question: '',
          answer: ''
        },
        character: 0,
        hiddenCircles: []
      },
      components: [],
      copyComponents: [],
      pasteComponents: [],
      selectedComponents: [],
      isShiftKey: false,
      fileList: [],
      fileGroupList: [],
      undoDataList: [],
      redoDataList: [],
      modelStates: ['idle', 'walk', 'talk'],
      modelEmotes: ['point', 'attention', 'suggestion2', 'suggestion', 'greet', 'handsup'],
      animationTargetComponentTypes: ['video', 'audio', 'text', 'people', 'image'],
      animationTimings: ['none', 'started', 'ended'],
      animationControl: ['play', 'stop'],
      animationList: [],
      animationModalList: [],
      selectedAnimationList: [],
      defaultAnimationData: {
        contentsId: '',
        animationId: '',
        status: {
          width: 100,
          height: 100,
          left: 0,
          top: 0,
          x: 0,
          y: 3,
          z: 10,
          r: 0,
          opacity: 1,
          zIndex: 0,
          state: 'Idle',
          emote: '',
          angry: 0,
          surprised: 0,
          sad: 0
        },
        timing: {
          target: '',
          action: 'none'
        },
        targetComponent: {
          componentId: '',
          type: 'image',
          play: 'play'
        },
        duration: 1000,
        prevDelay: 1000,
        afterDelay: 1000
      },
      animationData: {
        contentsId: '',
        animationId: '',
        status: {
          width: 100,
          height: 100,
          left: 0,
          top: 0,
          x: 0,
          y: 3,
          z: 10,
          r: 0,
          opacity: 1,
          zIndex: 0,
          state: 'Idle',
          emote: '',
          angry: 0,
          surprised: 0,
          sad: 0
        },
        timing: {
          target: '',
          action: 'none'
        },
        targetComponent: {
          componentId: '',
          type: 'image',
          play: 'play'
        },
        duration: 1000,
        prevDelay: 1000,
        afterDelay: 1000
      },
      selectedAnimationTarget: null
    }
  },
  mounted () {
    core.index()
    this.checkSession()
    this.init()
    this.listenKeyEvent()
  },
  filters: {
    checkEmpty (value) {
      return typeof value !== 'number' ? 0 : value
    }
  },
  methods: {
    getContentsStyle (item) {
      let data = {}
      if (item.thumbnail) {
        data = {
          backgroundImage: 'url(' + item.thumbnail + ')',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }
      }
      return data
    },
    capturImg () {
      const ths = this
      if (ths.selectedContentsList.length === 0) {
        return
      }

      html2canvas($('.ai-studio-center-wrap')[0], { useCORS: true, allowTaint: true }).then(_canvas => {
        // console.log($('.ai-studio-center-wrap')[0])
        const image = _canvas.toDataURL('image/png', 0.01)
        ths.selectedContentsList[0].thumbnail = image
        if (ths.selectedContentsList[0].state !== 'I' && ths.selectedContentsList[0].state !== 'D') {
          ths.selectedContentsList[0].state = 'U'
        }
      }).catch(e => { console.log(e) })
    },
    changeOption (obj) {
      if (obj.state !== 'I' && obj.state !== 'D') {
        obj.state = 'U'
      }
    },
    checkSession () {
      const tokens = document.cookie.split(';')
      let token = ''
      for (let i = 0; i < tokens.length; i++) {
        if (tokens[i].indexOf('csrftoken=') > -1) {
          token = tokens[i].split('csrftoken=')[1]
        }
      }

      const rs = _axios.axiosCheckSession(token)
      rs.then((response) => {
        if (response.data.code !== '0000') {
          location.href = '/admin/'
        }
      })
        .catch((e) => {
          if (e.response.status === 403) {
            location.href = '/admin/'
          }
        })
    },
    onRedo () {
      if (this.redoDataList.length === 0) {
        return
      }

      const undoData = {
        contents: [],
        components: [],
        fileGroup: [],
        isCreated: false,
        isDeleted: false
      }

      const data = this.redoDataList[0]
      if (data.isCreated === true) {
        undoData.isCreated = false
        undoData.isDeleted = true

        if (data.contents && data.contents.length > 0) {
          for (let i = 0; i < data.contents.length; i++) {
            const item = _.cloneDeep(data.contents[i])
            if (item.state === 'I') {
              this.contentsList = this.contentsList.filter(itm => itm.contentsId !== item.contentsId)
            } else {
              const contents = this.contentsList.filter(itm => itm.contentsId === item.contentsId)[0]
              contents.state = 'D'
            }
            undoData.contents.push(item)
          }
        }

        if (data.components && data.components.length > 0) {
          for (let i = 0; i < data.components.length; i++) {
            const item = _.cloneDeep(data.components[i])
            if (item.state === 'I') {
              this.components = this.components.filter(itm => itm.componentId !== item.componentId)
            } else {
              const component = this.components.filter(itm => itm.componentId === item.componentId)[0]
              component.state = 'D'
            }
            undoData.components.push(item)
          }
        }

        if (data.fileGroup && data.fileGroup.length > 0) {
          for (let i = 0; i < data.fileGroup.length; i++) {
            const item = _.cloneDeep(data.fileGroup[i])
            if (item.state === 'I') { // 생성되면 무조건 I임
              this.fileGroupList = this.fileGroupList.filter(itm => !(itm.fileId === item.fileId && itm.componentId === item.componentId))
            } else {
              const fileGroup = this.fileGroupList.filter(itm => itm.fileId === item.fileId && itm.componentId === item.componentId)[0]
              fileGroup.state = 'D'
            }
            undoData.fileGroup.push(item)
          }
        }
        this.redoDataList.shift()
        this.undoDataList.unshift(undoData)
      } else if (data.isDeleted === true) {
        undoData.isCreated = true
        undoData.isDeleted = false
        if (data.fileGroup && data.fileGroup.length > 0) {
          for (let i = 0; i < data.fileGroup.length; i++) {
            const item = _.cloneDeep(data.fileGroup[i])
            if (item.state === 'I') {
              this.fileGroupList.push(item)
            } else {
              const fileGroup = this.fileGroupList.filter(itm => itm.fileId === item.fileId && itm.componentId === item.componentId)[0]
              fileGroup.state = item.state
            }
            undoData.fileGroup.push(item)
          }
        }

        if (data.components && data.components.length > 0) {
          for (let i = 0; i < data.components.length; i++) {
            const item = _.cloneDeep(data.components[i])
            if (item.state === 'I') {
              this.components.push(item)
            } else {
              const component = this.components.filter(itm => itm.componentId === item.componentId)[0]
              component.state = item.state
            }
            undoData.components.push(item)
          }
        }

        if (data.contents && data.contents.length > 0) {
          for (let i = 0; i < data.contents.length; i++) {
            const item = _.cloneDeep(data.contents[i])
            if (item.state === 'I') {
              this.contentsList.push(item)
            } else {
              const contents = this.contentsList.filter(itm => itm.contentsId === item.contentsId)[0]
              contents.state = item.state
            }
            undoData.contents.push(item)
          }
        }
        this.redoDataList.shift()
        this.undoDataList.unshift(undoData)
      } else {
        undoData.isCreated = false
        undoData.isDeleted = false

        if (data.components && data.components.length > 0) {
          for (let i = 0; i < data.components.length; i++) {
            const item = _.cloneDeep(data.components[i])
            const component = this.components.filter(itm => itm.componentId === item.componentId)[0]
            const index = this.components.indexOf(component)
            this.components.splice(index, 1, item)
            undoData.components.push(_.cloneDeep(component))
          }
        }
        this.redoDataList.shift()
        this.undoDataList.unshift(undoData)
      }
      // console.log(this.undoDataList)
      // console.log(this.redoDataList)
    },
    onUndo () {
      if (this.undoDataList.length === 0) {
        return
      }

      const redoData = {
        contents: [],
        components: [],
        fileGroup: [],
        isCreated: false,
        isDeleted: false
      }

      const data = this.undoDataList[0]
      if (data.isCreated === true) {
        redoData.isCreated = false
        redoData.isDeleted = true

        if (data.contents && data.contents.length > 0) {
          for (let i = 0; i < data.contents.length; i++) {
            const item = _.cloneDeep(data.contents[i])
            if (item.state === 'I') {
              this.contentsList = this.contentsList.filter(itm => itm.contentsId !== item.contentsId)
            } else {
              const contents = this.contentsList.filter(itm => itm.contentsId === item.contentsId)[0]
              contents.state = 'D'
            }
            redoData.contents.push(item)
          }
        }

        if (data.components && data.components.length > 0) {
          for (let i = 0; i < data.components.length; i++) {
            const item = _.cloneDeep(data.components[i])
            if (item.state === 'I') {
              this.components = this.components.filter(itm => itm.componentId !== item.componentId)
            } else {
              const component = this.components.filter(itm => itm.componentId === item.componentId)[0]
              component.state = 'D'
            }
            redoData.components.push(item)
          }
        }

        if (data.fileGroup && data.fileGroup.length > 0) {
          for (let i = 0; i < data.fileGroup.length; i++) {
            const item = _.cloneDeep(data.fileGroup[i])
            if (item.state === 'I') { // 생성되면 무조건 I임
              this.fileGroupList = this.fileGroupList.filter(itm => !(itm.fileId === item.fileId && itm.componentId === item.componentId))
            } else {
              const fileGroup = this.fileGroupList.filter(itm => itm.fileId === item.fileId && itm.componentId === item.componentId)[0]
              fileGroup.state = 'D'
            }
            redoData.fileGroup.push(item)
          }
        }

        this.undoDataList.shift()
        this.redoDataList.unshift(redoData)
      } else if (data.isDeleted === true) {
        redoData.isCreated = true
        redoData.isDeleted = false
        if (data.fileGroup && data.fileGroup.length > 0) {
          for (let i = 0; i < data.fileGroup.length; i++) {
            const item = _.cloneDeep(data.fileGroup[i])
            if (item.state === 'I') {
              this.fileGroupList.push(item)
            } else {
              const fileGroup = this.fileGroupList.filter(itm => itm.fileId === item.fileId && itm.componentId === item.componentId)[0]
              fileGroup.state = item.state
            }
            redoData.fileGroup.push(item)
          }
        }

        if (data.components && data.components.length > 0) {
          for (let i = 0; i < data.components.length; i++) {
            const item = _.cloneDeep(data.components[i])
            if (item.state === 'I') {
              this.components.push(item)
            } else {
              const component = this.components.filter(itm => itm.componentId === item.componentId)[0]
              component.state = item.state
            }
            redoData.components.push(item)
          }
        }

        if (data.contents && data.contents.length > 0) {
          for (let i = 0; i < data.contents.length; i++) {
            const item = _.cloneDeep(data.contents[i])
            if (item.state === 'I') {
              this.contentsList.push(item)
            } else {
              const contents = this.contentsList.filter(itm => itm.contentsId === item.contentsId)[0]
              contents.state = item.state
            }
            redoData.contents.push(item)
          }
        }
        this.undoDataList.shift()
        this.redoDataList.unshift(redoData)
      } else {
        redoData.isCreated = false
        redoData.isDeleted = false

        if (data.components && data.components.length > 0) {
          for (let i = 0; i < data.components.length; i++) {
            const item = _.cloneDeep(data.components[i])
            const component = this.components.filter(itm => itm.componentId === item.componentId)[0]
            const index = this.components.indexOf(component)
            this.components.splice(index, 1, item)
            redoData.components.push(_.cloneDeep(component))
          }
        }
        this.undoDataList.shift()
        this.redoDataList.unshift(redoData)
      }
      // console.log(this.undoDataList)
      // console.log(this.redoDataList)
    },
    async init () {
      if (location.href.indexOf('?data=') > -1) {
        this.projectId = location.href.split('?data=')[1]
      } else {
        location.href = '/admin/'
      }
      try {
        let rs = await _axios.axiosGetProject(this.projectId)
        this.projectName = rs.data.name || ''

        rs = await _axios.axiosGetContentsList(this.projectId)
        for (let i = 0; i < rs.data.length; i++) {
          const data = JSON.parse(rs.data[i].data)
          data.code = rs.data[i].code
          this.contentsList.push(data)
        }
        if (this.contentsList.length === 0) {
          const pageComp = {
            projectId: this.projectId,
            contentsId: utils.getMaxId(this.contentsList, 'contentsId') + 1,
            background: '#f8f9fa',
            thumbnail: '',
            code: '',
            keywords: '',
            state: 'I'
          }
          this.contentsList.push(pageComp)
          this.selectedContentsList = []
          this.selectedContentsList.push(pageComp)
          return
        } else {
          this.selectedContentsList.push(this.contentsList[0])
        }

        rs = await _axios.axiosGetFileGroupList(this.projectId)
        const fileIdList = []
        for (let i = 0; i < rs.data.length; i++) {
          const compFileGroup = {
            projectId: rs.data[i].project,
            contentsId: rs.data[i].contents_id,
            componentId: rs.data[i].component_id,
            fileId: rs.data[i].file,
            sort: rs.data[i].sort,
            state: 'S'
          }
          this.fileGroupList.push(compFileGroup)
          if (fileIdList.filter(item => item === rs.data[i].file).length === 0) {
            fileIdList.push(rs.data[i].file)
          }
        }

        for (let i = 0; i < fileIdList.length; i++) {
          const rs2 = await _axios.axiosGetFile(fileIdList[i])
          const item = rs2.data
          const fileData = {
            fileId: item.file_id,
            name: item.name,
            size: item.size,
            file: null,
            type: item.type,
            target: item.target,
            tmpUrl: '',
            url: item.url,
            state: 'S'
          }
          this.fileList.push(fileData)
        }

        rs = await _axios.axiosGetComponentList(this.projectId)
        for (let i = 0; i < rs.data.length; i++) {
          const data = JSON.parse(rs.data[i].data)
          this.components.push(data)
        }

        rs = await _axios.axiosGetAnimationList(this.projectId)
        for (let i = 0; i < rs.data.length; i++) {
          const data = JSON.parse(rs.data[i].data)
          this.animationList.push(data)
        }
      } catch (e) {
        const pageComp = {
          projectId: this.projectId,
          contentsId: utils.getMaxId(this.contentsList, 'contentsId') + 1,
          background: '#f8f9fa',
          thumbnail: '',
          code: '',
          keywords: '',
          state: 'I'
        }
        this.contentsList.push(pageComp)
        this.selectedContentsList = []
        this.selectedContentsList.push(pageComp)
      }
    },
    getFile (componentId) {
      const fileList = []
      const fileGroupList = this.fileGroupList.filter(itm => itm.componentId === componentId)
      for (let i = 0; i < fileGroupList.length; i++) {
        for (let j = 0; j < this.fileList.length; j++) {
          if (fileGroupList[i].fileId === this.fileList[j].fileId) {
            fileList.push(this.fileList[j])
          }
        }
      }
      return fileList
    },
    getSrc (item) {
      let url = ''
      if (item.type === 'video' || item.type === 'audio' || item.type === 'image') {
        const fileData = this.getFile(item.componentId)
        if (fileData.length === 0) {
          return ''
        }
        if (fileData[0].state === 'I') {
          url = fileData[0].tmpUrl
        } else {
          url = fileData[0].url
          url = url.replace(this.replaceUrl, '')
          // console.log(url)
        }
      }
      if (item.type === 'people') {
        url = this.characterList[item.character].src
      }
      // console.log(url)
      return url
    },
    /* 저장기능 시작 */
    async onSave () {
      this.checkSession()
      // 업로드 해야 할 파일 정리
      const formData = new FormData()
      const uploadFileList = this.fileList.filter(itm => itm.state === 'I')
      for (let i = 0; i < uploadFileList.length; i++) {
        const item = uploadFileList[i]
        formData.append('file_' + i, item.file)
        formData.append('old_file_' + i, item.fileId)
        formData.append('target_file_' + i, item.target)
        formData.append('type_file_' + i, item.type)
      }
      let rs = null
      if (uploadFileList.length > 0) {
        rs = await _axios.axiosUploadFiles(formData)
        if (rs.data.code !== '0000') {
          return
        }
        // 업로드 해야 할 파일 그룹 데이터 정리
        for (let i = 0; i < rs.data.data.length; i++) {
          const data = rs.data.data[i]
          const fileGroup = this.fileGroupList.filter(itm => itm.fileId === data.oldFileId)
          for (let j = 0; j < fileGroup.length; j++) {
            fileGroup[j].fileId = data.fileId
          }
          const fileList = this.fileList.filter(itm => itm.fileId === data.oldFileId)
          for (let j = 0; j < fileList.length; j++) {
            fileList[j].url = data.url
            fileList[j].fileId = data.fileId
            fileList[j].state = 'S'
          }
        }
      }
      // 컨텐츠 저장
      // insert 컴포넌트
      const insertComponents = this.components.filter(itm => itm.state === 'I')
      if (insertComponents.length > 0) {
        for (let i = 0; i < insertComponents.length; i++) {
          insertComponents[i].state = 'S'
        }
        rs = await _axios.axiosInsertComponents(insertComponents)
        if (rs.data.code !== '0000') {
          for (let i = 0; i < insertComponents.length; i++) {
            insertComponents[i].state = 'I'
          }
        }
      }
      // update 컴포넌트
      const updateComponents = this.components.filter(itm => itm.state === 'U')
      if (updateComponents.length > 0) {
        for (let i = 0; i < updateComponents.length; i++) {
          updateComponents[i].state = 'S'
        }
        rs = await _axios.axiosUpdateComponents(updateComponents)
        if (rs.data.code !== '0000') {
          for (let i = 0; i < updateComponents.length; i++) {
            updateComponents[i].state = 'U'
          }
        }
      }
      // delete 컴포넌트
      const deleteComponents = this.components.filter(itm => itm.state === 'D')
      if (deleteComponents.length > 0) {
        rs = await _axios.axiosDeleteComponents(deleteComponents)
        if (rs.data.code === '0000') {
          for (let i = 0; i < updateComponents.length; i++) {
            const index = this.components.indexOf(updateComponents[i])
            this.components.splice(index, 1)
          }
        }
      }
      // insert 컨텐츠
      const insertContents = this.contentsList.filter(itm => itm.state === 'I')
      if (insertContents.length > 0) {
        for (let i = 0; i < insertContents.length; i++) {
          insertContents[i].state = 'S'
        }
        rs = await _axios.axiosInsertContents(insertContents)
        if (rs.data.code !== '0000') {
          for (let i = 0; i < insertContents.length; i++) {
            insertContents[i].state = 'I'
          }
        }
      }
      // update 컨텐츠
      const updateContents = this.contentsList.filter(itm => itm.state === 'U')
      if (updateContents.length > 0) {
        for (let i = 0; i < updateContents.length; i++) {
          updateContents[i].state = 'S'
        }
        rs = await _axios.axiosUpdateContents(updateContents)
        if (rs.data.code !== '0000') {
          for (let i = 0; i < updateContents.length; i++) {
            updateContents[i].state = 'U'
          }
        }
      }
      // delete 컨텐츠
      const deleteContents = this.contentsList.filter(itm => itm.state === 'D')
      if (deleteContents.length > 0) {
        rs = await _axios.axiosDeleteContents(deleteContents)
        if (rs.data.code === '0000') {
          for (let i = 0; i < deleteContents.length; i++) {
            const index = this.contentsList.indexOf(deleteContents[i])
            this.contentsList.splice(index, 1)
          }
        }
      }
      // insert 파일그룹
      const insertFileGroup = this.fileGroupList.filter(itm => itm.state === 'I')
      if (insertFileGroup.length > 0) {
        for (let i = 0; i < insertFileGroup.length; i++) {
          insertFileGroup[i].state = 'S'
        }
        rs = await _axios.axiosInsertFileGroups(insertFileGroup)
        if (rs.data.code !== '0000') {
          for (let i = 0; i < insertFileGroup.length; i++) {
            insertFileGroup[i].state = 'I'
          }
        }
      }
      // delete 파일그룹
      const deleteFileGroup = this.fileGroupList.filter(itm => itm.state === 'D')
      if (deleteFileGroup.length > 0) {
        rs = await _axios.axiosDeleteFileGroups(deleteFileGroup)
        if (rs.data.code === '0000') {
          for (let i = 0; i < deleteFileGroup.length; i++) {
            const index = this.fileGroupList.indexOf(deleteFileGroup[i])
            this.fileGroupList.splice(index, 1)
          }
        }
      }
      // insert Animation
      const insertAnimations = this.animationList.filter(itm => itm.state === 'I')
      if (insertAnimations.length > 0) {
        for (let i = 0; i < insertAnimations.length; i++) {
          insertAnimations[i].state = 'S'
        }
        rs = await _axios.axiosInsertAnimations(insertAnimations)
        if (rs.data.code !== '0000') {
          for (let i = 0; i < insertAnimations.length; i++) {
            insertAnimations[i].state = 'I'
          }
        }
      }
      // update Animation
      const updateAnimations = this.animationList.filter(itm => itm.state === 'U')
      if (updateAnimations.length > 0) {
        for (let i = 0; i < updateAnimations.length; i++) {
          updateAnimations[i].state = 'S'
        }
        rs = await _axios.axiosUpdateAnimations(updateAnimations)
        if (rs.data.code !== '0000') {
          for (let i = 0; i < updateAnimations.length; i++) {
            updateAnimations[i].state = 'U'
          }
        }
      }
      // delete Animation
      const deleteAnimations = this.animationList.filter(itm => itm.state === 'D')
      if (deleteAnimations.length > 0) {
        rs = await _axios.axiosDeleteAnimations(deleteAnimations)
        if (rs.data.code === '0000') {
          for (let i = 0; i < deleteAnimations.length; i++) {
            const index = this.animationList.indexOf(deleteAnimations[i])
            this.animationList.splice(index, 1)
          }
        }
      }
      // 저장시 언두 리두 삭제 임시방편
      this.undoDataList = []
      this.redoDataList = []
    },
    /* 저장기능 끝 */
    /* 키입력 이벤트 컨트롤 영역 시작 */
    listenKeyEvent () {
      window.addEventListener('keydown', (e) => {
        if (e.ctrlKey === true) {
          if (e.key === 'c' || e.key === 'C') {
            this.copySelectedComponents()
          }
          if (e.key === 'v' || e.key === 'V') {
            this.pasteCopiedComponents()
          }
          if (e.key === 's' || e.key === 'S') {
            this.onSave()
          }
          if (e.shiftKey === false && (e.key === 'z' || e.key === 'Z')) {
            this.onUndo()
          }
          if (e.shiftKey === true && (e.key === 'z' || e.key === 'Z')) {
            this.onRedo()
          }
        }

        if (e.key === 'Delete') {
          this.deleteComponent()
        }

        if (e.shiftKey === true) {
          this.isShiftKey = true
        }
      })

      window.addEventListener('keyup', (e) => {
        if (e.key === 'Shift') {
          this.isShiftKey = false
        }
      })
    },
    /* 키입력 이벤트 컨트롤 영역 끝 */
    /* 컨텐츠 시작 */
    selectContents (item, index) { // 컨텐츠 선택
      this.selectedContentsList = []
      this.selectedComponents = []
      this.selectedAnimationList = []
      this.selectedContentsList.push(item)
    },
    addContents () { // 컨텐츠 추가
      const pageComp = {
        projectId: this.projectId,
        contentsId: utils.getMaxId(this.contentsList, 'contentsId') + 1,
        background: '#f8f9fa',
        thumbnail: '',
        code: '',
        keywords: '',
        state: 'I'
      }
      this.contentsList.push(pageComp)
      this.selectedContentsList = []
      this.selectedContentsList.push(pageComp)
      // undo 준비
      const undoData = {
        contents: [_.cloneDeep(pageComp)],
        isCreated: true,
        isDeleted: false
      }
      this.undoDataList.unshift(undoData)
    },
    deleteContents () { // 컨텐츠 삭제
      if (this.selectedContentsList.length === 0) {
        return
      }
      let focusIndex = this.contentsList.indexOf(this.selectedContentsList[0])
      if (focusIndex <= 0) {
        focusIndex = 0
      } else {
        focusIndex = focusIndex - 1
      }

      const undoData = {
        contents: [],
        components: [],
        fileGroup: [],
        isCreated: false,
        isDeleted: true
      }
      for (let i = 0; i < this.selectedContentsList.length; i++) {
        const item = this.selectedContentsList[i]
        // undo 준비
        undoData.contents.push(_.cloneDeep(item))
        if (item.state === 'I') {
          const index = this.contentsList.indexOf(item)
          this.contentsList.splice(index, 1)
        } else {
          item.state = 'D'
        }

        const deletedComponentList = this.components.filter(itm => itm.contentsId === item.contentsId)
        for (let j = 0; j < deletedComponentList.length; j++) {
          const item2 = deletedComponentList[j]
          // undo 준비
          undoData.components.push(_.cloneDeep(item2))
          if (item2.state === 'I') {
            const index = this.components.indexOf(item2)
            this.components.splice(index, 1)
          } else {
            item2.state = 'D'
          }
          const deleteFileGroupList = this.fileGroupList.filter(itm => itm.componentId === item2.componentId)
          for (let k = 0; k < deleteFileGroupList.length; k++) {
            undoData.contents.push(_.cloneDeep(deleteFileGroupList[k]))
            this.deleteFileGroup(deleteFileGroupList[k])
          }
        }

        const deletedAnimationList = this.animationList.filter(itm => itm.contentsId === item.contentsId)
        for (let j = 0; j < deletedAnimationList.length; j++) {
          const item3 = deletedAnimationList[j]
          if (item3.state === 'I') {
            const index = this.animationList.indexOf(item3)
            this.animationList.splice(index, 1)
          } else {
            item3.state = 'D'
          }
        }
      }
      this.selectedContentsList = []
      const tmpList = this.contentsList.filter(itm => itm.state !== 'D')
      if (tmpList.length === 0) {
        this.addContents()
      } else {
        this.selectedContentsList.push(tmpList[focusIndex])
      }
      this.undoDataList.unshift(undoData)
      this.redoDataList = []
    },
    /* 컨텐츠 끝 */
    /* 컴포넌트 시작 */
    changeLang (lang) {
      const ths = this
      ths.selectedComponents[0].lang = lang
      if (ths.selectedComponents[0].state !== 'I' && ths.selectedComponents[0].state !== 'D') {
        ths.selectedComponents[0].state = 'U'
      }
    },
    addComponent (data, type) { // 컴포넌트 추가
      let text = ''
      let circles = []
      const lang = 'ko-KR'

      if (type === 'text') {
        text = this.modalData.text
      }

      if (type === 'hiddenCatch') {
        circles = this.modalData.hiddenCircles
      }

      const undoData = {
        components: [],
        fileGroup: [],
        isCreated: true,
        isDeleted: false
      }

      const comp = {
        projectId: this.projectId,
        contentsId: this.selectedContentsList[0].contentsId,
        componentId: utils.getMaxId(this.components, 'componentId') + 1,
        left: 150,
        top: 150,
        height: type === 'people' ? 556 : 150,
        width: type === 'people' ? 346 : 150,
        text: text,
        question: this.modalData.quiz.question,
        answer: this.modalData.quiz.answer,
        circles: circles,
        lang: lang,
        type: type,
        zIndex: 0,
        opacity: 1,
        character: this.modalData.character,
        state: 'I'
      }

      this.components.push(comp)
      this.selectComponent(comp)
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          const fileId = this.addUploadFile(comp, data[i], i)
          const compFileGroup = {
            projectId: comp.projectId,
            contentsId: comp.contentsId,
            componentId: comp.componentId,
            fileId: fileId,
            sort: 0,
            state: 'I'
          }
          this.fileGroupList.push(compFileGroup)
          undoData.fileGroup.push(_.cloneDeep(compFileGroup))
        }
      }
      undoData.components.push(_.cloneDeep(comp))
      this.undoDataList.unshift(undoData)
      this.redoDataList = []
      this.capturImg()
    },
    deleteComponent () { // 컴포넌트 삭제
      if (this.selectedComponents.length === 0) {
        return
      }

      const undoData = {
        components: [],
        fileGroup: [],
        isCreated: false,
        isDeleted: true
      }

      for (let i = 0; i < this.selectedComponents.length; i++) {
        const item = this.selectedComponents[i]
        undoData.components.push(_.cloneDeep(item))
        if (item.state === 'I') {
          const index = this.components.indexOf(item)
          this.components.splice(index, 1)
        } else {
          item.state = 'D'
        }
        const deleteFileGroupList = this.fileGroupList.filter(itm => itm.componentId === item.componentId)
        for (let j = 0; j < deleteFileGroupList.length; j++) {
          undoData.fileGroup.push(_.cloneDeep(deleteFileGroupList[j]))
          this.deleteFileGroup(deleteFileGroupList[j])
        }
      }
      this.undoDataList.unshift(undoData)
      this.redoDataList = []
      this.capturImg()
    },
    copySelectedComponents () { // 컴포넌트 복사하기
      if (this.selectedComponents.length === 0) { // selectedComponents가 없을경우 리턴
        return
      }

      this.copyComponents = []
      for (let i = 0; i < this.selectedComponents.length; i++) { // 삭제해도 컴포넌트가 남아있도록 컴포넌트 재구성
        const item = this.selectedComponents[i]
        const comp = {
          projectId: this.projectId,
          contentsId: item.contentsId,
          componentId: item.componentId,
          left: item.left,
          top: item.top,
          height: item.height,
          width: item.width,
          text: item.text,
          question: item.question,
          answer: item.answer,
          circles: item.circles,
          lang: item.lang,
          type: item.type,
          zIndex: item.zIndex,
          opacity: 1,
          character: item.character,
          state: 'I'
        }
        this.copyComponents.push(comp)
      }
    },
    pasteCopiedComponents () { // 컴포넌트 붙여넣기
      if (this.copyComponents.length === 0) { // copyComponents가 없을경우 리턴
        return
      }

      const undoData = {
        components: [],
        fileGroup: [],
        isCreated: true,
        isDeleted: false
      }

      const tmpComponents = []
      this.selectedComponents = []
      for (let i = 0; i < this.copyComponents.length; i++) {
        const item = this.copyComponents[i]
        const comp = {
          projectId: this.projectId,
          contentsId: this.selectedContentsList[0].contentsId,
          componentId: utils.getMaxId(this.components, 'componentId') + i + 1,
          left: item.left + 10,
          top: item.top + 10,
          height: item.height,
          width: item.width,
          text: item.text,
          question: item.question,
          answer: item.answer,
          circles: item.circles,
          lang: item.lang,
          type: item.type,
          zIndex: item.zIndex,
          opacity: 1,
          character: item.character,
          state: 'I'
        }
        tmpComponents.push(comp)
        this.selectedComponents.push(comp)

        undoData.components.push(_.cloneDeep(comp))

        if (comp.type === 'video' || comp.type === 'audio' || comp.type === 'image' || comp.type === 'hiddenCatch') {
          const fileGroupList = this.fileGroupList.filter(itm => itm.componentId === item.componentId)
          if (fileGroupList.length > 0) {
            for (let i = 0; i < fileGroupList.length; i++) {
              const compFileGroup = {
                projectId: comp.projectId,
                contentsId: comp.contentsId,
                componentId: comp.componentId,
                fileId: fileGroupList[i].fileId,
                sort: 0,
                state: 'I'
              }
              this.fileGroupList.push(compFileGroup)
              undoData.fileGroup.push(_.cloneDeep(compFileGroup))
            }
          }
        }
      }
      this.copyComponents = tmpComponents
      this.components = [...this.components, ...tmpComponents]
      this.undoDataList.unshift(undoData)
      this.redoDataList = []
    },
    /** 파일 관리 영역 시작 */
    addUploadFile (comp, data, index) {
      const fileData = {
        fileId: comp.projectId + '_' + comp.contentsId + '_' + comp.componentId + '_' + index,
        name: data[0].name,
        size: data[0].size,
        file: data[0],
        type: comp.type,
        target: 'component',
        tmpUrl: URL.createObjectURL(data[0]),
        url: '',
        state: 'I'
      }
      this.fileList.push(fileData)
      return fileData.fileId
    },
    deleteFile (data) {
      if (data.state === 'I') {
        this.fileList = this.fileList.filter(item => item !== data)
      }
    },
    addFileGroup (comp, fileId) {
      const compFileGroup = {
        projectId: comp.projectId,
        contentsId: comp.contentsId,
        componentId: comp.componentId,
        fileId: fileId,
        sort: 0,
        state: 'I'
      }
      this.fileGroupList.push(compFileGroup)
    },
    deleteFileGroup (data) {
      if (data.state === 'I') {
        const index = this.fileGroupList.indexOf(data)
        this.fileGroupList.splice(index, 1)
      } else {
        data.state = 'D'
      }
    },
    /** 파일 관리 영역 끝 */
    selectComponent (obj) { // 컴포넌트 선택
      // console.log(obj)
      if (this.isShiftKey === false) {
        this.selectedComponents = []
      }
      this.selectedComponents.push(obj)
    },
    clearSelect (e) { // 셀렉트 취소하는 영역 지정하여 빈곳 클릭시 셀렉트 컴포넌트 해제
      if (e.target.className && e.target.className.indexOf('ai-studio-center-wrap') > -1 && e.target.className && e.target.className.indexOf('studio-page') < 0 && e.target.className.indexOf('component') < 0 && e.target.className.indexOf('resizable')) {
        this.selectedComponents = []
      }
    },
    /** vue-resizeable 컨트롤 영역 시작 */
    eHandler (data) { // vue-resizeable 라이브러리 수정이 필요하여 임시방편으로 컴포넌트 아이디를 클래스로 넘기고 해당클래스 비교하여 원하는 컴포넌트 선택
      const componentId = data.cmp.$el.className.split('component_')[1]
      const selectedComponentList = this.selectedComponents.filter(item => item.componentId + '' === componentId)
      if (data.eventName === 'drag:start' || data.eventName === 'resize:start') {
        const componentList = this.components.filter(item => item.componentId + '' === componentId)
        if (componentList.length === 0) {
          return
        }
        const undoData = {
          components: [],
          isCreated: false,
          isDeleted: false
        }
        undoData.components.push(_.cloneDeep(componentList[0]))
        this.undoDataList.unshift(undoData)
        this.redoDataList = []
      }
      if (data.eventName === 'drag:end' || data.eventName === 'resize:end') {
        const component = selectedComponentList[0]
        component.width = data.width
        component.height = data.height
        component.left = data.left
        component.top = data.top
        if (component.state !== 'I') {
          component.state = 'U'
        }
        this.capturImg()
      }
    },
    /** vue-resizeable 컨트롤 영역 끝 */
    /** 모달 시작 */
    handleModalShow (e) {
      this.modalData.img = null
      this.modalData.img2 = null
      this.modalData.text = ''
      this.modalData.hiddenCircles = []
      this.modalFiles = []
      this.modalFiles.push(0)
    },
    handleModalHide (e) { // 모달이 사라질때 이벤트 캐치하여 ok인지 아닌지 확인
      const type = e.componentId.replace('modal-', '')
      if (e.trigger === 'ok') {
        if (type === 'animation') {
          if (this.animationList.filter(itm => itm.animationId === this.animationData.animationId).length > 0) {
            this.updateAnimation()
          } else {
            this.addAnimation()
          }
        } else if (type === 'hiddenCatch') {
          this.addComponent([this.modalData.file, this.modalData.file2], type)
        } else {
          this.addComponent([this.modalData.file], type)
        }
      } else {
        this.modalData.file = null
        this.modalData.file2 = null
        this.clearAnimationData()
      }
    },
    /** 모달 끝 */
    /* 애니메이션 시작 */
    selectAnimation (item) {
      this.selectedAnimationList = []
      this.selectedAnimationList.push(item)
    },
    addAnimation () {
      const item = { ...this.animationData }
      item.projectId = this.projectId
      item.contentsId = this.selectedContentsList[0].contentsId
      item.state = 'I'
      item.animationId = utils.getMaxId(this.animationList, 'animationId') + 1
      this.animationList.push(item)
      this.clearAnimationData()
    },
    updateAnimation () {
      const item = { ...this.animationData }
      item.projectId = this.projectId
      item.contentsId = this.selectedContentsList[0].contentsId
      if (item.state !== 'I' && item.state !== 'D') {
        item.state = 'U'
      }
      const selectedAnimation = this.animationList.filter(itm => itm.animationId === item.animationId)[0]
      this.animationList.splice(this.animationList.indexOf(selectedAnimation), 1, item)
      this.clearAnimationData()
    },
    deleteAnimation (data) {
      if (data.state === 'I') {
        const index = this.animationList.indexOf(data)
        this.animationList.splice(index, 1)
      } else {
        data.state = 'D'
      }
    },
    showAnimationModal (item) {
      this.animationData.animationId = item.animationId
      this.animationData = item
      this.selectedAnimationTarget = this.components.filter(itm => itm.componentId === item.targetComponent.componentId)[0]
      this.componentModal.animation = true
    },
    clearAnimationData () {
      this.animationData = _.cloneDeep(this.defaultAnimationData)
      this.selectedAnimationTarget = null
    }
    /** 애니메이션 끝 */
    /* 컴포넌트 끝 */
  }
}
</script>
